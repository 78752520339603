<template>
    <div class="messageS">
        <el-badge :value="computedData" class="item">
            <div class="msgBack" >
                <img class="xiaoxi-icon" src="@/assets/img/messLing.svg" style="width: 100px;height: auto;"
                :class="{ 'rotate': data?true:false }">
            </div>
        </el-badge>

    </div>
</template>
<script>
export default {
    props:['data'],
    data() {
        return {
            
        }
    },
    computed:{
        computedData(){
            return 'New '+ this.data + '条'
        }
    }
}
</script>
<style lang="scss">
.msgBack {
    cursor: pointer;
    border: 1px dashed rgba($color: #ccc, $alpha: .5);
    padding: 20px;
    border-radius: 5px;
}

.rotate {
    display: block;
    animation: rotate 0.8s linear infinite;
}

@keyframes rotate {
    0%{
        transform: scale(0.8);
        /*从0度开始*/
    }
    50%{
        transform: scale(1.2);
        /*从0度开始*/
    }
    from{
        transform: scale(1);
    }
}</style>